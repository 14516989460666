  //Cambio de valor de botón aprobar

  $(document).on('click', '.aprobar-planificacion', function() {
      $(this).find('input[type="hidden"]').val();
      $("#editPlanif").submit();
  });

  // Chequeo de cambios
  var $form = $('form'),
      origForm = $form.serialize();

  $(document).on('change input', 'form input', function(origForm) {
      formActual = $('form').serialize();
      if (origForm != formActual) {
          $('.change-message').show();
      } else {
          $('.change-message').hide();
      }
  });

  // Agrega nuevo objetivo específico
  $(document).on('click', "#addObjetivosEsp", function() {
      var row = $('#tablaObjetivosEsp').find('tbody tr:last-child').clone();
      row.find('input').val('');
      row.find('.delObjetivoEsp').attr('data-id', '');
      $('#tablaObjetivosEsp').find('tbody').append(row);
  });

  // Elimina objetivo específico
  $(document).on('click', ".delObjetivoEsp", function() {
      if ($(this).parents('table').find('tbody tr').length != 1) {
          if ($(this).data("id") != null) {
              $('<input>').attr({
                  type: 'hidden',
                  name: 'delObjetivos[]',
                  value: $(this).data("id")
              }).appendTo('#editPlanif');
          }
          $(this).parents('tr').remove();
      }
  });

  //Restricción fechas

  jQuery(function($) {
      $('#desdeFecha').on('change', function() {
          $('#hastaFecha').prop('min', $(this).val());
      });
      $('#hastaFecha').on('change', function() {
          $('#desdeFecha').prop('max', $(this).val());
      });
  });
