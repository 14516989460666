// Google Charts a imagen
var getImgData, saveAsImg;

getImgData = function(chartContainer) {
  var canvas, chartArea, doc, imgData, svg;
  chartArea = chartContainer.getElementsByTagName('svg')[0].parentNode;
  svg = '<svg>' + chartContainer.getElementsByTagName('svg')[0].innerHTML + '</svg>'
  doc = chartContainer.ownerDocument;
  canvas = doc.createElement('canvas');
  canvas.setAttribute('width', chartArea.offsetWidth);
  canvas.setAttribute('height', chartArea.offsetHeight);
  canvas.setAttribute('style', 'position: absolute; ' + 'top: ' + -chartArea.offsetHeight * 2 + 'px;' + 'left: ' + -chartArea.offsetWidth * 2 + 'px;');
  doc.body.appendChild(canvas);
  canvg(canvas, svg);
  imgData = canvas.toDataURL('image/png');
  canvas.parentNode.removeChild(canvas);
  return imgData;
};

saveAsImg = function(chartContainer) {
  var imgData;
  imgData = getImgData(chartContainer);
  window.location = imgData.replace('image/png', 'image/octet-stream');
};
