/**
 * Created by samuelrojaspenaloza on 04-05-16.
 */

// Responsive fonts
$('body').flowtype();

// muestra elmiminar sobre td editables
$(document).on('mouseenter', '.removable', function() {
    if (!$(this).hasClass('editing')) {
        $(this).children().last().append('<a class="pull-right eliminar">Eliminar</a>');
    }
});

$(document).on('mouseleave', '.removable', function() {
    $(this).find('a.eliminar').remove();
});


$(document).on('click', '.addto', function() {
    var prototype = $('.prototypes ' + $(this).data('prototype')).clone();
    prototype.addClass('nuevo-control');
    var addto = $(this).data("addto");
    $(addto).append(prototype);
});

$(document).on('click', '.eliminar', function() {
    $(this).parents('.removable').remove();
})

$(document).on('dblclick', '.editable span', function() {
    $(this).parent().addClass('editing');
    $(this).parent().find('a.eliminar').remove();
    var prototype = $('.prototypes ' + $(this).parent().data('prototype')).clone();
    $(this).hide();
    $(this).parent().append(prototype);
    $(this).parent().find(prototype).val($(this).html());
    $(this).parent().find(prototype).focus();
})

$(document).on('keyup', '.editable input, .editable select, .editable textarea', function(e) {
    if (e.which == 27) {
        if ($(this).parents('.removable').hasClass('nuevo-control')) {
            $(this).parents('.removable').remove();
        } else {
            $(this).parent().find('span').show();
            $(this).parent().removeClass('editing');
            $(this).remove();
        }
    }
});

$(document).on('keyup', '.editable input, .editable select, .editable textarea', function(e) {
    if (e.which == 13 && $(this).val() != "") {
        var texto = $(this).val();
        if ($(this).parents('.removable').hasClass('nuevo-control')) {
            $(this).parent().append('<span>' + texto + '</span>');
        } else {
            $(this).parent().find('span').html(texto);
            $(this).parent().find('span').show();
        }
        $(this).parent().removeClass('editing');
        $(this).parents('.removable').removeClass('nuevo-control');
        $(this).remove();
    }
});


$(document).on('mouseenter', '.aprobado, .borrador', function() {
    $(this).find('.glyphicon').removeClass('hidden');
});

$(document).on('mouseleave', '.aprobado, .borrador', function() {
    $(this).find('.glyphicon').addClass('hidden');
});

$(document).on('click', '.aprobado, .borrador', function() {
    $('#aprobacion').submit();
});

$(document).on('click', 'a.submit', function() {
    var form = $(this).parents('form');
    form.submit();
});

$(document).ready(function() {
    $('.responsive-calendar').responsiveCalendar({});
});

$(function() {
    $('[data-toggle="tooltip"]').tooltip()
})

$(document).on('click', '.btn-back', function() {
    window.history.back();
})
